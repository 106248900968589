<template>
    <a-modal title="Bank operation's information" visible :onCancel="handleCancel" okText="Done" @ok="onSubmit">
        <div class="project-modal">
            <FormValidationWrap>
                <VerticalFormStyleWrap>
                    <a-form name="sDash_validation-form" ref="formRef" @finish="onSubmit" :model="formState"
                        :rules="rules" :layout="formState.layout">
                        <a-row :gutter="25">
                            <a-col :span="12">
                                <a-form-item label="Bank Account" name="bankAccountId">
                                    <a-select v-model:value="formState.bankAccountId" placeholder="Bank Account">
                                        <a-select-option v-for="(bank, index) in AccountApi" :key="index"
                                            :value="bank.id">
                                            {{ bank.bankName }} &nbsp;-&nbsp; {{ bank.name }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item label="Expense" name="incomeOutcome">
                                    <a-select v-model:value="formState.incomeOutcome" disabled placeholder="Expense">
                                        <a-select-option :value="1">
                                            Income
                                        </a-select-option>
                                        <a-select-option :value="2">
                                            Outcome
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :span="12" v-if="formState.incomeOutcome == 2">
                                <a-form-item label="Payment Method" name="paymentMethodId">
                                    <a-select v-model:value="formState.paymentMethodId"
                                        placeholder="Select a payment methode">
                                        <a-select-option v-for="(payment, index) in PaymentMethodApi" :key="index"
                                            :value="payment.id">
                                            {{ payment.name }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item label="Expense" name="bankOperationTypeId">
                                    <a-select v-model:value="formState.bankOperationTypeId" placeholder="Expense">
                                        <a-select-opt-group label="Outcome">
                                            <a-select-option v-for="(
                                expenseType, index
                              ) in ExpenseTypeApi.filter(
                                  (operation) => operation.incomeOrOutcome == 2 && operation.isOperation
                              )" :key="index" :value="expenseType.id">
                                                {{ expenseType.name }}</a-select-option>
                                        </a-select-opt-group>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :xs="12" v-if="isPurchasingOfGoods"><a-form-item label="Partner"
                                    name="partenaireId">
                                    <a-select v-if="partnerApi.length" v-model:value="formState.partenaireId"
                                        placeholder="Please choose Owner" show-search style="width: 100%" size="large"
                                        :options="partnerData" :not-found-content="partnerFetching ? undefined : null
                                            " :filter-option="false"
                                        @search="(value) => searchPartner(value, formState.operationSupport == 1, formState.operationSupport == 2)"
                                        @change="fetchPartnerPurchase('')">
                                        <template v-if="partnerFetching" #notFoundContent>
                                            <a-spin size="small" />
                                        </template>
                                    </a-select>
                                    <a-select v-else v-model:value="formState.partenaireId"
                                        placeholder="Please choose Owner">
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :span="12" :xs="12">
                                <a-form-item name="date" label="Date">
                                    <a-date-picker v-model:value="formState.date" :style="{ width: '100%' }"
                                        :format="dateFormat" />
                                </a-form-item> </a-col>
                            <a-col :md="24" :xs="24" v-if="isPurchasingOfGoods"><a-form-item label="Purchase Number"
                                    name="purchaseIds">
                                    <a-select v-model:value="formState.purchaseIds" placeholder="Select PO"
                                        mode="multiple" style="width: 100%;" :filter-option="false" show-search
                                        :disabled="!formState.partenaireId"
                                        :not-found-content="purchaseState.fetching ? undefined : null"
                                        :options="purchaseState.data" @search="fetchPartnerPurchase"
                                        @change="setAmount">
                                        <template v-if="purchaseState.fetching" #notFoundContent>
                                            <a-spin size="small" />
                                        </template>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :xs="12">
                                <a-form-item ref="amount" label="Amount" name="amount">
                                    <a-input-number :style="{ width: '100%' }" size="small"
                                        v-model:value="formState.amount" />
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :xs="12">
                                <a-form-item ref="vat" label="Vat" name="vat">
                                    <a-input-number :style="{ width: '100%' }" :min="0" :max="100"
                                        :formatter="(value) => `${value}%`" :parser="(value) => value.replace('%', '')"
                                        v-model:value="formState.vat" placeholder="VAT" />
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-form>
                </VerticalFormStyleWrap>
            </FormValidationWrap>
        </div>
    </a-modal>
</template>
<script setup>
import { useStore } from "vuex";
import { reactive, ref, computed, defineProps, onMounted, defineEmits } from "vue";
import moment from "moment";
import usePartnerSearch from "@/composable/usePartnerSearch";
import {
    FormValidationWrap,
    VerticalFormStyleWrap,
} from "../../forms/overview/Style";
import { DataService } from "@/config/dataService/dataService";
import { debounce } from 'lodash-es';

const { state, dispatch } = useStore();

/// Apiss data
const AccountApi = computed(() => state.AccountApi.data);
const ExpenseTypeApi = computed(
    () => state.ExpenseTypeApi.data
);
const PaymentMethodApi = computed(() => state.PaymentMethodApi.data);
const partnerApi = computed(() => state.partnerApi.data);
const purchaseState = reactive({
    data: [],
    value: [],
    fetching: false,
});
////
const props = defineProps(['bankOperation', 'pagination']);
const emit = defineEmits(['cancel'])
//form
const dateFormat = "YYYY/MM/DD";

const rules = {
    bankOperationTypeId: [
        {
            required: true,
            message: "Expense type is required",
            trigger: "change",
        },
    ],
    date: [
        {
            type: "object",
            required: true,
            message: "Date is required",
            trigger: "change",
        },
    ],
    amount: [
        {
            type: "number",
            required: true,
            message: "Amount is required",
            trigger: "change",
        },
    ],
    bankAccountId: [
        {
            required: true,
            message: "Bank account is required",
            trigger: "change",
        },
    ],
    partenaireId: [
        {
            required: true,
            message: "Partner is required",
            trigger: "change",
        },
    ],
    occasionalName: [
        {
            required: true,
            message: "Name is required",
            trigger: "change",
        },
    ],
    isPartnerOrOccasional: [
        {
            type: "number",
            required: true,
            message: "Benificiary is required",
            trigger: "change",
        },
    ],
    paymentMethodId: [
        {
            required: true,
            message: "Payment Method is required",
            trigger: "change",
        },
    ],
    purchaseIds: [
        {
            type: "array",
            required: true,
            message: "Purchase number is required",
            trigger: "change",
        },
    ],
};
const formRef = ref();
var formState = reactive({
    layout: "vertical",
    id: 0,
    name: "Name",
    date: "",
    estimatedTime: "",
    isPartnerOrOccasional: null,
    amount: null,
    occasionalName: "",
    incomeOutcome: 2,
    vat: 0,
    bankAccountId: null,
    paymentMethodId: null,
    operationSupport: 1,
    partenaireId: null,
    occasionalLocalExternal: null,
    purchaseIds: [],
    Note: "",
});

const isPurchasingOfGoods = computed(() => {
    const expenseType = ExpenseTypeApi.value.find(obj => obj.id === formState.bankOperationTypeId)
    return expenseType?.code == "purchasing_goods"
})

const handleEdit = (bankOperation) => {
    formState.editionMode = true;
    formState.id = bankOperation.id;
    formState.name = bankOperation.name;
    formState.date = moment(bankOperation.date);
    formState.estimatedTime = moment(bankOperation.estimatedTime);
    formState.amount = bankOperation.amount;
    formState.occasionalName = bankOperation.occasionalName;
    formState.isPartnerOrOccasional = bankOperation.isPartnerOrOccasional;
    formState.bankAccountId = bankOperation.bankAccountId;
    formState.bankOperationTypeId = bankOperation.bankOperationTypeId;
    formState.paymentMethodId = bankOperation.paymentMethodId;
    formState.operationSupport = 1;
    if (bankOperation.partenaireId) {
        partnerData.value.push({
            value: bankOperation.partenaireId,
            label: bankOperation.partenaireName,
        });
    }
    formState.partenaireId = bankOperation.partenaireId;
    formState.occasionalLocalExternal = bankOperation.occasionalLocalExternal;
    formState.vat = bankOperation.vat;
    formState.Note = bankOperation.Note;
    fetchPartnerPurchase('');
};

const onSubmit = () => {
    formRef.value
        .validate()
        .then(() => {
            formState.name = "hideedn"
            if (!formState.editionMode) {
                dispatch("bankOperationSubmitData", {
                    formState,
                    pagination: props.pagination
                });
            } else {
                dispatch("bankOperationUpdateData", {
                    formState,
                    pagination: props.pagination
                });
            }
            handleCancel();
        })
        .catch((error) => {
            console.log("error", error);
        });
};

const { partnerData, searchPartner } = usePartnerSearch(
    formState.partenaireId
);

onMounted(() => {
    emptyPartner();
    if (props.bankOperation) {
        handleEdit(props.bankOperation);
    } else {
        formState.editionMode = false;
        delete formState.id;
        formState.name = "";
        formState.date = "";
        formState.estimatedTime = "";
        formState.isPartnerOrOccasional = null;
        formState.amount = "";
        formState.bankOperationTypeId = null;
        formState.paymentMethodId = null;
        formState.operationSupport = 1;
        formState.occasionalName = "";
        formState.partenaireId = "";
        formState.bankAccountId = "";
        formState.purchaseIds = [];
        formState.vat = 0;
        formState.Note = "";
        formState.occasionalLocalExternal = "";
    }
});

const handleCancel = () => {
    emit('cancel');
}

const emptyPartner = () => {
    partnerData.value = [];
    formState.partenaireId = null;
};
const setAmount = () => {
    if (formState.purchaseIds.length == 0) {
        formState.amount = 0; 
    }else{
        formState.amount = purchaseState.data
            .filter(purchase => formState.purchaseIds.includes(purchase.id)) 
            .reduce((sum, purchase) => sum + purchase.amount, 0); 
    }

};

let lastPartnerPurchaseIds = 0;
const fetchPartnerPurchase = debounce(value => {
    console.log(value);
    lastPartnerPurchaseIds += 1;
    const fetchId = lastPartnerPurchaseIds;
    purchaseState.data = [];
    purchaseState.fetching = true;
    DataService.get(
        `api/Purchase/GetAllPurchaseWithExpenseDetails?OnlyNotUsed=false&PartnerId=${formState.partenaireId}`
    )
        .then((response) => response.data)
        .then((list) => {
            if (fetchId !== lastPartnerPurchaseIds) {
                return;
            }
            
            const data = list.map((element) => ({
                label: `${element.purchaseNumber}(${element.expenseSupplierReceiptReference})`,
                id: element.purchaseId,
                value: element.purchaseId,
                amount: element.expenseTotal,
            }));
            purchaseState.data = data;
            purchaseState.fetching = false;

            if (props.bankOperation) {
                formState.purchaseIds = props.bankOperation.purchaseIds;
            }
        });
}, 300);
</script>